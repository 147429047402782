<template>
  <v-app>
    <div style="background: #e7e7e7">
      <template v-if="loading">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80vh;
          "
        >
          <v-progress-circular
            :size="80"
            :width="7"
            color="primary"
            indeterminate
          />
        </div>
      </template>
      <template v-else>
        <template>
          <div class="text-center">
            <v-dialog v-model="alert" width="350">
              <v-card>
                <v-card-title :class="typeError()" primary-title>
                  {{ $t(msgTitleAlert) }}
                </v-card-title>

                <v-card-text>
                  <div v-html="$t(msgAlert)" />
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                  <v-spacer />

                  <v-btn color="secondary" text @click="alert = false">
                    Ok
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="show">
                    <v-card-text>
                      <span style="color: #666666; font-size: 8px"
                        >{{ query }}
                      </span>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <router-view />
      </template>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import i18n from "@/i18n";
import moment from "moment";
import { getToken, getAppLanguage } from "@vegga/front-utils";

export default {
  name: "App",
  data() {
    return {
      loading: true,
      show: false,
      type: null,
      msgAlert: null,
      msgTitleAlert: null,
      dialog1: false,
      lang: "pt",
      alert: false,

      query: null,
    };
  },
  computed: {
    ...mapState(["login", "tokenProgres"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    typeError() {
      this.type = "grey";
      return "headline " + this.type + " lighten-2";
    },
    async initialize() {
      var locale = getAppLanguage();
      i18n.locale = locale;
      moment.locale(locale);
      this.loading = true;
      var formData = {
        token: await getToken(),
      };

      var result = await this.$store.dispatch("getVegga", formData);

      /*
        formData = {
          user: 'test+irrigation-manager@veggadigital.com',
          password: 'iranilda',
        }

        result = await this.$store.dispatch('login', formData)
        */

      if (result.type === "e") {
        this.query = result.query;
        this.msgAlert = result.response;
        this.msgTitleAlert = "msg.error";
        this.type = "accent";
        this.alert = true;
      } else {
        if (result.response) {
          const inputVar = {
            tokens: {
              tokenIM: result.response.imToken,
            },
          };
          result = await this.$store.dispatch("getImuserProfile", inputVar);

          if (result.type === "e") {
            this.query = result.query;
            this.msgAlert = result.response;
            this.msgTitleAlert = "msg.error";
            this.type = "accent";
            this.alert = true;
          } else {
            this.$store.state.userId = result.response.imuserId;
            this.$store.state.userId = result.response.imuserId;
            this.$store.state.userName = result.response.imuserName;
            this.$store.state.bg = result.response.imuserBg;

            if (
              result.response.imuserBg === "#27353a" ||
              result.response.imuserBg === "#0a6082" ||
              result.response.imuserBg === "#000000"
            ) {
              this.$store.state.fontC = "#ffffff";
            } else {
              this.$store.state.fontC = "#000000";
            }
            if (result.response.prof.accountId === null) {
              this.$store.state.superUser = true;
            }
          }
        }

        const inputVar = {
          tokens: {
            tokenIM: this.login,
          },
        };

        await this.$store.dispatch("getTokenProgres", inputVar);
        await this.$store.dispatch("getAccount", inputVar);
        result = await this.$store.dispatch("getTabRefs", inputVar);

        if (result.type.findIndex((i) => i === "e") > -1) {
          this.query =
            result.query +
            " - " +
            result.tariff[result.type.findIndex((i) => i === "e")];
          this.msgAlert =
            result.response[result.type.findIndex((i) => i === "e")];
          this.msgTitleAlert = "msg.error";
          this.type = "accent";
          this.alert = true;
        }
        await this.$store.dispatch("getETariff", inputVar);
      }
      this.loading = false;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/pages");
    },
  },
};
</script>
