import Vue from 'vue'
import Router from 'vue-router'
import farmsChildren from './views/farms/farms.router'
import solidChildren from './views/solid/solid.router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Dashboard'),
  },

  {
    path: '/farms',
    name: 'Farms',
    component: () => import('@/views/farms/farms'),
    children: farmsChildren,
  },
  {
    path: '/solid',
    name: 'solid',
    component: () => import('@/views/solid/solid'),
    children: solidChildren,
  },

  {
    name: 'Stocks',
    path: '/stocks',
    component: () => import('@/views/dashboard/stocks/stocks'),
  },
  {
    name: 'Submit',
    path: '/submit',
    component: () => import('@/views/dashboard/submit/submit'),
  },
  {
    name: 'Result',
    path: '/results',
    component: () => import('@/views/dashboard/submit/results'),
  },

  {
    name: 'Settings',
    path: '/settings',
    component: () => import('@/views/dashboard/settings/settings'),
  },
  {
    name: 'ClientAdmin',
    path: '/clientAdmin',
    component: () => import('@/views/dashboard/admin/clientAdmin'),
  },
  {
    name: 'Admin',
    path: '/admin',
    component: () => import('@/views/dashboard/admin/admin'),
  },

  {
    name: 'Proposta',
    path: '/settingsAdmin/proposta',
    component: () => import('@/views/dashboard/admin/proposta'),
  },
  {
    name: 'ImportControllers',
    path: '/settingsAdmin/importControllers',
    component: () => import('@/views/dashboard/admin/importControllers'),
  },
]

//TODO - after migration remove preview
const router = new Router({
  mode: 'history',
  base: 'ea-irrigation-manager',
  routes,
})

export default router
