<template>
  <v-app>
    <v-card>
      <v-tabs>
        <v-tabs-slider color="buttomClass" />

        <v-tab
          v-for="item in items"
          :key="item.key"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-card
      class="v-card--wizard"
      elevation="12"
    >
      <v-tabs>
        <v-tabs-slider
          class="mt-1"
          color="buttomClass"
        />

        <v-tab
          v-for="(item, i) in items"
          :key="i"
          :ripple="false"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
    </v-card>
  </v-app>
</template>

<script>

  export default {

    data () {
      return {
        show: false,
        query: null,
        search: '',
        tab: 0,
        tab1: 0,
        tabM: 0,
        previous: 0,
        previous1: 0,
        msgAlert: null,
        msgTitleAlert: 'msg.alert',
        alert: false,
        dialog: false,
        dialog1: false,
        response: null,
        id: null,
        nameRouter: null,
        selId: null,
        val: [],
        val0: false,
        val1: false,
        mark: [],
        offsetX: false,
        offsetY: true,
        fList: [],
        items: [
          {
            id: 0,
            title: 'menu.account',
            icon: 'mdi-gate',

          },
          {
            id: 1,
            title: 'menu.users',
            icon: 'mdi-chart-donut',

          },

          { id: 2, title: 'label.contacts', icon: 'mdi-chart-donut-variant' },
          { id: 3, title: 'label.dosers', icon: 'mdi-flower' },
          { id: 4, title: 'label.tariff', icon: 'mdi-water-pump' },
          { id: 5, title: 'label.fertilizers', icon: 'mdi-scale-balance' },
          { id: 6, title: 'label.waterPlan', icon: 'mdi-scale-balance' },

        ],
        items2: [
          { id: 0, title: 'label.corporations' },
          { id: 1, title: 'label.supplier' },
          { id: 2, title: 'label.technician' },
          { id: 3, title: 'label.officer' },

        ],
        computedFarm: '',

      }
    },
  }
</script>

<style lang="sass">
  .v-card--wizard
    overflow: visible

    .v-tabs-bar
      height: 56px
      padding: 0 8px

    .v-tabs-slider-wrapper
      overflow: visible

    .v-tabs-slider
      border-radius: 4px

    .v-tabs-slider-wrapper
      contain: initial
      z-index: 0
</style>
