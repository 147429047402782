// https://vuex.vuejs.org/en/state.html

export default {
  counter: 0,
  bg: '#27353a',
  fontC: '#ffffff',
  tabSel: null,
  subTabSel: null,
  saveItems: false,
  saveItem: [
    { id: 0, state: false },
    { id: 1, state: false },
    { id: 2, state: false },
    { id: 3, state: false },
    { id: 4, state: false },
    { id: 5, state: false },
    { id: 6, state: false },
    { id: 7, state: false },
  ],
  farmIdent: null,

  showTariff: false,
  showSupplier: false,
  showSeason: false,
  showRate: false,
  tariffId: null,
  groupSector: false,
  corporation: false,
  fertOutput: false,
  container: false,
  login: null,
  userId: null,
  userName: null,
  apiPath: 'https://irrigationweb.herokuapp.com/graphql/',
  barColor: 'rgba(66, 179, 219, 0.2), rgba(0, 0, 0, .8)',
  barImage: '',
  drawer: null,
  controllers: [],
  farms: [],
  farmsMap: [],
  oneFarm: [],
  account: [],
  sectorsGroup: [],
  corporations: [],
  contacts: [],
  imusers: [],
  userProfiles: [],
  unitProfileRef: [],
  cloudUsersRef: [],
  accountStatus: [],
  userProfile: [],
  cloudUsers: [],
  dosers: [],
  parcelTypes: [],
  plantSpacing: [],
  irrigSpace: [],
  soilType: [],
  cultivarType: [],
  cultivarVariety: [],
  contactType: [],
  doserRefs: [],
  alarmSeverity: [],
  containers: [],
  fertOutputs: [],
  fertControls: [],
  fertPlans: [],
  fertRefs: [],
  fertInventoryRefs: [],
  tarifRefs: [],
  eRate: [],
  eTariff: [],
  eSupplier: [],
  eSeason: [],
  eSupplierRate: [],
  eTariffPlan: [],
  irrReq: [],
  irrReqId: [],
  irrReqByDate: [],
  editedItem: '',
  viewItem: '',
  dialogView: false,
  calcIrrig: null,
  planIrrig: null,
  calcIrrigPlanIni: null,
  existWaterPrescription: false,
  waterPrescription: [],
  isNewIrrReq: false,
  fertPl: [],
  importResults: [],
  integrateCycle: [],
  dashRegFert: null,
  dashFert1: null,
  dashFert2: null,
  dashFert3: null,
  dashWater1: null,
  dashWater2: null,
  dashWater4: null,
  dashWater3: null,
  dashWater5: null,
  wPlanFarms: null,
  accounts: null,
  /* if submit false => no edit */
  submit: false,
  changeFarm: false,
  changePassword: false,
  changeCat: 0,
  changeDiv: 0,
  showFilters: false,
  headersHours: [],
  superUser: false,
  year: null,
  proposals: null,
  solids: null,
  showSupplierProd: false,
  supplierProd: [],
  allSupplierProd: [],
  fertOrders: [],
  fertSupplier: [],
  createProgram: [],
  programProfileRef: [],
  irrRotationRef: [],
  systemRef: [],
  imuser: '',
  alarms: [],
  alarmRefs: [],
  farmAlarm: null,
  containerAssoc: [],
  dashboardFilteredLabelItems: [],
  dashboardLabelItems: [],
};
