// https://vuex.vuejs.org/en/mutations.html

export default {
  updateCounter(state, payload) {
    state.counter = payload;
  },
  updateGlobal(state, payload) {
    state.global = payload;
  },
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
  SET_SCRIM(state, payload) {
    state.barColor = payload;
  },
  SET_BG(state, payload) {
    state.bg = payload;
  },
  setLogin(state, payload) {
    state.login = payload;
  },
  logout(state) {
    state.tokenProgres = "";
    state.tokenIM = "";
  },
  setTokenProgres(state, payload) {
    state.tokenProgres = payload;
  },
  setAccount(state, payload) {
    state.account = payload;
  },
  setAccounts(state, payload) {
    state.accounts = payload;
  },
  setControllers(state, payload) {
    state.controllers = payload;
  },
  setFarms(state, payload) {
    state.farms = payload;
  },
  setFarmById(state, payload) {
    state.oneFarm = payload;
  },
  setSectorsGroup(state, payload) {
    state.sectorsGroup = payload;
  },
  setCorporations(state, payload) {
    state.corporations = payload;
  },
  setContacts(state, payload) {
    state.contacts = payload;
  },
  setImusers(state, payload) {
    state.imusers = payload;
  },
  setDosers(state, payload) {
    state.dosers = payload;
  },
  setContainers(state, payload) {
    state.containers = payload;
  },
  setFertOutputs(state, payload) {
    state.fertOutputs = payload;
  },
  setFertControls(state, payload) {
    state.fertControls = payload;
  },
  setFertPlans(state, payload) {
    state.fertPlans = payload;
  },
  setFertRefs(state, payload) {
    state.fertRefs = payload;
  },
  setFertInventoryRefs(state, payload) {
    state.fertInventoryRefs = payload;
  },
  setParcelTypes(state, payload) {
    state.parcelTypes = payload;
  },
  setPlantSpacing(state, payload) {
    state.plantSpacing = payload;
  },
  setIrrigSpace(state, payload) {
    state.irrigSpace = payload;
  },
  setSoilType(state, payload) {
    state.soilType = payload;
  },

  setCultivarType(state, payload) {
    state.cultivarType = payload;
  },
  setCultivarVariety(state, payload) {
    state.cultivarVariety = payload;
  },
  setContactType(state, payload) {
    state.contactType = payload;
  },
  setAlarmSeverity(state, payload) {
    state.alarmSeverity = payload;
  },
  setAlarmRefs(state, payload) {
    state.alarmRefs = payload;
  },
  setUserProfiles(state, payload) {
    state.userProfiles = payload;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  setUnitProfileRef(state, payload) {
    state.unitProfileRef = payload;
  },
  setCloudUsersRef(state, payload) {
    state.cloudUsersRef = payload;
  },
  setAccountStatus(state, payload) {
    state.accountStatus = payload;
  },
  setCloudUsers(state, payload) {
    state.cloudUsers = payload;
  },
  setDoserRefs(state, payload) {
    state.doserRefs = payload;
  },
  setProgramProfileRef(state, payload) {
    state.programProfileRef = payload;
  },
  setTarifRefs(state, payload) {
    state.tarifRefs = payload;
  },
  setERate(state, payload) {
    state.eRate = payload;
  },
  setESeason(state, payload) {
    state.eSeason = payload;
  },
  setESupplier(state, payload) {
    state.eSupplier = payload;
  },
  setESupplierRate(state, payload) {
    state.eSupplierRate = payload;
  },
  setETariff(state, payload) {
    state.eTariff = payload;
  },
  setETariffPlan(state, payload) {
    state.eTariffPlan = payload;
  },
  setIrrReq(state, payload) {
    state.irrReq = payload;
  },
  setIrrReqId(state, payload) {
    state.irrReqId = payload;
  },
  setIrrReqByDate(state, payload) {
    state.irrReqByDate = payload;
  },
  setImportResults(state, payload) {
    state.importResults = payload;
  },
  setIntegrateCycle(state, payload) {
    state.integrateCycle = payload;
  },
  setDashRegFert(state, payload) {
    state.dashRegFert = payload;
  },
  setDashFert1(state, payload) {
    state.dashFert1 = payload;
  },
  setDashFert2(state, payload) {
    state.dashFert2 = payload;
  },
  setDashFert3(state, payload) {
    state.dashFert3 = payload;
  },
  setDashWater1(state, payload) {
    state.dashWater1 = payload;
  },
  setDashWater2(state, payload) {
    state.dashWater2 = payload;
  },
  setDashWater3(state, payload) {
    state.dashWater3 = payload;
  },
  setDashWater4(state, payload) {
    state.dashWater4 = payload;
  },
  setDashWater5(state, payload) {
    state.dashWater5 = payload;
  },
  setWPlanFarms(state, payload) {
    state.wPlanFarms = payload;
  },
  setProposals(state, payload) {
    state.proposals = payload;
  },
  setSolids(state, payload) {
    state.solids = payload;
  },
  setSupplierProd(state, payload) {
    state.supplierProd = payload;
  },
  setFertSupplier(state, payload) {
    state.fertSupplier = payload;
  },
  setAllSupplierProd(state, payload) {
    state.allSupplierProd = payload;
  },
  setFertOrders(state, payload) {
    state.fertOrders = payload;
  },
  setCreateProgram(state, payload) {
    state.createProgram = payload;
  },
  setIrrRotationRef(state, payload) {
    state.irrRotationRef = payload;
  },
  setSystemRefs(state, payload) {
    state.systemRef = payload;
  },
  setImuser(state, payload) {
    state.imuser = payload;
  },
  setAlarms(state, payload) {
    state.alarms = payload;
  },
  setContainerAssoc(state, payload) {
    state.containerAssoc = payload;
  },
  setDashboardFilteredLabelItems(state, payload) {
    state.dashboardFilteredLabelItems = payload;
  },
  setDashboardLabelItems(state, payload) {
    state.dashboardLabelItems = payload;
  },
};
