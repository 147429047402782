import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  secondary: '#332E20',
  primary: '#D0C781',
  accent: '#FF6666',
  info: '#00CAE3',
  buttomClass: '#d0c781',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
   icons: {
    iconfont: 'mdi',
  },
})
